import type { TreeData, TreeNodeItem, TreeNodeList, TreeDataWithLevel } from '.';

const getPosition = (level: string | number, index: number) => `${level}-${index}`;

const leveling = (treeData: TreeData[], level: number): TreeDataWithLevel[] =>
  treeData.map<TreeDataWithLevel>((node) => {
    const result = { ...node, level } as TreeDataWithLevel;

    if (node.children) {
      return { ...result, children: leveling(node.children, level + 1) };
    }
    return result;
  });

const flatten = (treeData: TreeDataWithLevel[], parentKey?: string): TreeNodeList =>
  treeData.flatMap<TreeNodeItem>((node, index) => {
    const key = node.key ?? getPosition(parentKey ?? '0', index);
    const updatedNode = (parentKey ? { ...node, key, parentKey } : { ...node, key }) as TreeNodeItem;

    if (node.children) {
      const children = flatten(node.children, key);
      return [{ ...updatedNode, children }, ...children];
    }
    return updatedNode;
  });

export const constructTreeNodes = (treeData: TreeData[]): TreeNodeList => flatten(leveling(treeData, 0));

export const getCurrentSelectedNodes = (nodes: TreeNodeList, selectedNodes: TreeData[]): TreeNodeList =>
  nodes.filter((node) => selectedNodes.some((selectedNode) => selectedNode.value === node.value));

export const checkIsSelectedNode = (currentSelectedNodes: TreeNodeList, selectedItem: TreeNodeItem) =>
  currentSelectedNodes.some(
    (selectedNode) => selectedNode.key === selectedItem.key || selectedNode.key === selectedItem.parentKey,
  );

export const checkIsAllNodesSelected = (nodes: TreeNodeList, selectedNodes: TreeData[]) =>
  nodes
    .filter((node) => node.level === 0)
    .every((node) => checkIsSelectedNode(getCurrentSelectedNodes(nodes, selectedNodes), node));

export const checkIsExpandedNode = (expandedKeys: string[], key: string) =>
  expandedKeys.some((expandedKey) => expandedKey === key);

export const getSelectedLeafNodes = (selectedNodes: TreeData[]) =>
  selectedNodes.flatMap((node) => (node.children ? node.children : node));
