import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SparklesSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M13.2378 5.33196L13.7645 4.1653L14.9312 3.63863C15.1912 3.51863 15.1912 3.15196 14.9312 3.03196L13.7645 2.5053L13.2378 1.33196C13.1178 1.07196 12.7512 1.07196 12.6312 1.33196L12.1045 2.49863L10.9312 3.0253C10.6712 3.1453 10.6712 3.51196 10.9312 3.63196L12.0978 4.15863L12.6245 5.33196C12.7445 5.59196 13.1178 5.59196 13.2378 5.33196ZM7.93118 6.33196L6.87118 3.99863C6.63785 3.47863 5.89118 3.47863 5.65785 3.99863L4.59785 6.33196L2.26451 7.39196C1.74451 7.63196 1.74451 8.37196 2.26451 8.6053L4.59785 9.6653L5.65785 11.9986C5.89785 12.5186 6.63785 12.5186 6.87118 11.9986L7.93118 9.6653L10.2645 8.6053C10.7845 8.3653 10.7845 7.6253 10.2645 7.39196L7.93118 6.33196ZM12.6245 10.6653L12.0978 11.832L10.9312 12.3586C10.6712 12.4786 10.6712 12.8453 10.9312 12.9653L12.0978 13.492L12.6245 14.6653C12.7445 14.9253 13.1112 14.9253 13.2312 14.6653L13.7578 13.4986L14.9312 12.972C15.1912 12.852 15.1912 12.4853 14.9312 12.3653L13.7645 11.8386L13.2378 10.6653C13.1178 10.4053 12.7445 10.4053 12.6245 10.6653Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Sparkles = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SparklesSvg} {...props} />
));
Sparkles.displayName = 'Sparkles';
export default Sparkles;
