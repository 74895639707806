import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ArchiveSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m8.3 14.354 1.414-1.415 1.293 1.293V9h2v5.232L14.3 12.94l1.414 1.415-3.707 3.707L8.3 14.354z" />
      <path
        fillRule="evenodd"
        d="M19.535 2H4.465L2 5.697V19.5A2.5 2.5 0 0 0 4.5 22h15a2.5 2.5 0 0 0 2.5-2.5V5.697L19.535 2zM4.87 5l.666-1h12.93l.666 1H4.87zM4 7v12.5a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5V7H4z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Archive = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ArchiveSvg} {...props} />
));
Archive.displayName = 'Archive';
export default Archive;
